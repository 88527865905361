/* LoadingScreen.css */
#loading-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-image {
  width: 100px;
  height: 100px;
  animation: zoomIn 3s ease-in-out infinite;
}

.loading-text {
  font-size: 2rem;
  font-weight: bold;
  color: #3498db;
  animation: zoomIn 3s ease-in-out infinite;
}

.tagline-text {
  font-size: 1rem;
  font-weight: normal;
  color: #2c3e50;
  animation: zoomIn 3s ease-in-out infinite;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
