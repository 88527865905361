.loader-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 8px solid transparent;
  border-top-color: #ff6b6b; /* Soft Red */
  border-right-color: #feca57; /* Soft Orange */
  border-bottom-color: #1dd1a1; /* Soft Green */
  border-left-color: #54a0ff; /* Soft Blue */
  animation: spin 1.5s linear infinite, color-change 6s ease-in-out infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes color-change {
  0%,
  100% {
    border-top-color: #ff6b6b;
    border-right-color: #feca57;
    border-bottom-color: #1dd1a1;
    border-left-color: #54a0ff;
  }
  25% {
    border-top-color: #feca57;
    border-right-color: #1dd1a1;
    border-bottom-color: #54a0ff;
    border-left-color: #ff6b6b;
  }
  50% {
    border-top-color: #1dd1a1;
    border-right-color: #54a0ff;
    border-bottom-color: #ff6b6b;
    border-left-color: #feca57;
  }
  75% {
    border-top-color: #54a0ff;
    border-right-color: #ff6b6b;
    border-bottom-color: #feca57;
    border-left-color: #1dd1a1;
  }
}
